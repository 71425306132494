import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    screen: {
      main_screen: false,
    },
    socket: {
      bybit: null,
    },
    bybit_trade_list: [],
    bybit_kline_list: [],
    temp: 0,
    timer: null,
    timer2: null,
    timerRun: false,
    sum_buy_volume: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ],
    sum_sell_volume: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ],
    rate_buy_volume: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ],
    rate_sell_volume: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ],
    timestamp_gap: 0,
  },
  actions: {
    async timerStart() {
      try {
        if (this.state.timerRun === false) {
          await this.dispatch("bybit_connect");
          this.state.timerRun = true;
          this.state.timer = clearInterval(this.state.timer);
          this.state.timer = setInterval(() => {
            this.state.temp = this.state.temp + 1;
            if (this.state.socket.bybit != null) {
              if (this.state.socket.bybit.readyState != 1) {
                this.state.temp = this.state.temp + 10000;
                this.dispatch("bybit_connect");
              }
            }
          }, 1000);
          this.state.timer2 = clearInterval(this.state.timer2);
          this.state.timer2 = setInterval(() => {
            try {
              for (let i = 0; i < 30; i++) {
                this.state.sum_buy_volume[i] = 0;
                this.state.sum_sell_volume[i] = 0;
              }
              let datenow = Date.now() - this.state.timestamp_gap;

              for (let i = 0; i < this.state.bybit_trade_list.length; i++) {
                if (this.state.bybit_trade_list[i].taker === "Buy") {
                  for (let j = 1; j < 31; j++) {
                    if (
                      this.state.bybit_trade_list[i].timestamp + j * 2 * 1000 >=
                      datenow
                    ) {
                      this.state.sum_buy_volume[j - 1] += Number(
                        this.state.bybit_trade_list[i].volume
                      );
                    }
                  }
                } else {
                  for (let j = 1; j < 31; j++) {
                    if (
                      this.state.bybit_trade_list[i].timestamp + j * 2 * 1000 >=
                      datenow
                    ) {
                      this.state.sum_sell_volume[j - 1] += Number(
                        this.state.bybit_trade_list[i].volume
                      );
                    }
                  }
                }
              }
              for (let i = 0; i < 30; i++) {
                if (
                  this.state.sum_buy_volume[i] +
                    this.state.sum_sell_volume[i] ==
                  0
                ) {
                  this.state.rate_buy_volume[i] = 0;
                  this.state.rate_sell_volume[i] = 0;
                } else {
                  this.state.rate_buy_volume[i] =
                    Math.round(
                      (100 /
                        (this.state.sum_buy_volume[i] +
                          this.state.sum_sell_volume[i])) *
                        this.state.sum_buy_volume[i] *
                        10
                    ) / 10;
                  this.state.rate_sell_volume[i] =
                    Math.round(
                      (100 /
                        (this.state.sum_buy_volume[i] +
                          this.state.sum_sell_volume[i])) *
                        this.state.sum_sell_volume[i] *
                        10
                    ) / 10;
                }
              }
            } catch {
              console.log("timer2 error");
            }
          }, 20);
        }
      } catch {
        console.log("timer start error");
      }
    },
    timerStop() {
      try {
        if (this.state.timerRun === true) {
          this.state.timerRun = false;
          this.state.timer = clearInterval(this.state.timer);
          this.state.timer2 = clearInterval(this.state.timer2);
        }
      } catch {
        console.log("timer stop error");
      }
    },
    bybit_connect() {
      try {
        if (this.state.socket.bybit != null) {
          this.state.socket.bybit.close();
        }
        this.state.socket.bybit = new WebSocket(
          "wss://stream.bybit.com/v5/public/linear"
        );
        this.state.socket.bybit.binaryType = "arraybuffer";
        this.state.socket.bybit.onclose = () => {
          this.dispatch("bybit_connect");
        };
        this.state.socket.bybit.onopen = () => {
          this.state.socket.bybit.send(
            JSON.stringify({
              op: "subscribe",
              args: [
                "publicTrade.BTCUSDT",
                "publicTrade.ETHUSDT",
                "kline.M.BTCUSDT",
                "kline.M.ETHUSDT",
              ],
            })
          );
          this.state.socket.bybit.onmessage = (e) => {
            if (JSON.stringify(e.data).indexOf("publicTrade") > 0) {
              for (let i = 0; i < JSON.parse(e.data).data.length; i++) {
                this.state.bybit_trade_list.unshift({
                  timestamp: JSON.parse(e.data).data[i].T,
                  taker: JSON.parse(e.data).data[i].S,
                  volume:
                    JSON.parse(e.data).data[i].v * JSON.parse(e.data).data[i].p,
                });
                try {
                  if (JSON.parse(e.data).topic == "publicTrade.BTCUSDT") {
                    axios.post("https://www.0500.kr:333/bybit/btcusdt/trade", {
                      topic: JSON.parse(e.data).topic,
                      type: JSON.parse(e.data).type,
                      ts: JSON.parse(e.data).ts,
                      t: JSON.parse(e.data).data[i].T,
                      s: JSON.parse(e.data).data[i].s,
                      s2: JSON.parse(e.data).data[i].S,
                      v: JSON.parse(e.data).data[i].v,
                      p: JSON.parse(e.data).data[i].p,
                      l: JSON.parse(e.data).data[i].L,
                      i: JSON.parse(e.data).data[i].i,
                      bt: JSON.parse(e.data).data[i].BT,
                    });
                  }
                  if (JSON.parse(e.data).topic == "publicTrade.ETHUSDT") {
                    axios.post("https://www.0500.kr:333/bybit/ethusdt/trade", {
                      topic: JSON.parse(e.data).topic,
                      type: JSON.parse(e.data).type,
                      ts: JSON.parse(e.data).ts,
                      t: JSON.parse(e.data).data[i].T,
                      s: JSON.parse(e.data).data[i].s,
                      s2: JSON.parse(e.data).data[i].S,
                      v: JSON.parse(e.data).data[i].v,
                      p: JSON.parse(e.data).data[i].p,
                      l: JSON.parse(e.data).data[i].L,
                      i: JSON.parse(e.data).data[i].i,
                      bt: JSON.parse(e.data).data[i].BT,
                    });
                  }
                } catch {
                  console.log("post error");
                }
              }
              for (let i = 0; i < this.state.bybit_trade_list.length; i++) {
                if (
                  this.state.bybit_trade_list[i].timestamp + 60 * 1000 <=
                  this.state.bybit_trade_list[0].timestamp
                ) {
                  this.state.bybit_trade_list.pop(1);
                }
              }
              this.state.timestamp_gap =
                Date.now() - this.state.bybit_trade_list[0].timestamp;
            } else {
              if (JSON.stringify(e.data).indexOf("kline") > 0) {
                this.state.bybit_kline_list.unshift({
                  timestamp: JSON.parse(e.data).data[0].timestamp,
                  price: JSON.parse(e.data).data[0].close,
                  volume: JSON.parse(e.data).data[0].volume,
                });
                if (JSON.parse(e.data).topic == "kline.M.BTCUSDT") {
                  axios.post("https://www.0500.kr:333/bybit/btcusdt/kline", {
                    topic: JSON.parse(e.data).topic,
                    type: JSON.parse(e.data).type,
                    ts: JSON.parse(e.data).ts,
                    start: JSON.parse(e.data).data[0].start,
                    end: JSON.parse(e.data).data[0].end,
                    interval: JSON.parse(e.data).data[0].interval,
                    open: JSON.parse(e.data).data[0].open,
                    close: JSON.parse(e.data).data[0].close,
                    high: JSON.parse(e.data).data[0].high,
                    low: JSON.parse(e.data).data[0].low,
                    volume: JSON.parse(e.data).data[0].volume,
                    turnover: JSON.parse(e.data).data[0].turnover,
                    confirm: JSON.parse(e.data).data[0].confirm,
                    timestamp: JSON.parse(e.data).data[0].timestamp,
                  });
                }
                if (JSON.parse(e.data).topic == "kline.M.ETHUSDT") {
                  axios.post("https://www.0500.kr:333/bybit/ethusdt/kline", {
                    topic: JSON.parse(e.data).topic,
                    type: JSON.parse(e.data).type,
                    ts: JSON.parse(e.data).ts,
                    start: JSON.parse(e.data).data[0].start,
                    end: JSON.parse(e.data).data[0].end,
                    interval: JSON.parse(e.data).data[0].interval,
                    open: JSON.parse(e.data).data[0].open,
                    close: JSON.parse(e.data).data[0].close,
                    high: JSON.parse(e.data).data[0].high,
                    low: JSON.parse(e.data).data[0].low,
                    volume: JSON.parse(e.data).data[0].volume,
                    turnover: JSON.parse(e.data).data[0].turnover,
                    confirm: JSON.parse(e.data).data[0].confirm,
                    timestamp: JSON.parse(e.data).data[0].timestamp,
                  });
                }
                for (let i = 0; i < this.state.bybit_kline_list.length; i++) {
                  if (
                    this.state.bybit_kline_list[i].timestamp + 60 * 1000 <=
                    this.state.bybit_kline_list[0].timestamp
                  ) {
                    this.state.bybit_kline_list.pop(1);
                  }
                }
              } else {
                console.log(JSON.parse(e.data));
              }
            }
          };
        };
      } catch {
        console.log("bybit_connect error");
      }
    },
    bybittest() {
      try {
        this.state.socket.bybit.send(
          JSON.stringify({
            req_id: "test", // optional
            op: "subscribe",
            args: ["orderbook.1.ETHUSDT"],
          })
        );
        this.state.socket.bybit.send(
          JSON.stringify({
            req_id: "test", // optional
            op: "unsubscribe",
            args: ["orderbook.1.BTCUSDT", "publicTrade.BTCUSDT"],
          })
        );
      } catch {
        console.log("bybittest error");
      }
    },
  },
});
